<template>
  <div class="app-container home" id="home">
    <div class="header">
      <div class="background-imgs">
        <template>
          <div class="block">
            <el-carousel trigger="click" height="946px">
              <div class="personal-info">
                <div>
                  <template>
                    <div class="demo-type">
                      <div>
                        <el-avatar :size="120" :src="headImg"></el-avatar>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="personal-info-text">
                  <h3>{{ startText[0] }}</h3>
                  <h3>{{ startText[1] }}</h3>
                </div>
              </div>
              <el-carousel-item v-for="(item,index2) in urls.length" :key="index2">
                <div class="demo-image__placeholder">
                  <div class="demo-image__error">
                    <div class="block">
                      <el-image style="width: 100%; height: 946px" :src="urls[item-1]"></el-image>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </template>
      </div>
      <div class="header-bar">
        <span class="logo-image">
          <a href="/">
            <div class="block">
              <el-image style="width: 130px; height: 46px" :src="logo" fit="fill"></el-image>
            </div>
          </a>
        </span>
        <span class="menu">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <div class="logo-text-menu">
                  <i class="el-icon-s-unfold"></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index1) in bookmark" :key="index1">
                  <div v-if="isNull(item.path)">
                    {{ item.name }}
                  </div>
                  <div v-else-if="item.externalChain === 0">
                    <router-link :to="item.path">{{ item.name }}</router-link>
                  </div>
                  <div v-else>
                    <a :href="item.path">{{ item.name }}</a>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </span>
      </div>
      <div class="xia-hei-img">
        <div class="block">
          <el-image style="width: 100%; height: 380px" :src="xiaHei" fit="fill"></el-image>
        </div>
      </div>
    </div>
    <div>
      <div class="common"
           v-bind:style="{backgroundImage:'url(' + xian + ')', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%', height: backgroundHeight}">
        <div class="common-subject">
          <div class="common-text">
            <h2>{{ selfIntroductionText[0] }}</h2>
            <p>
              {{ selfIntroductionText[1] }}
              <span style="color: #5be301">{{ selfIntroductionText[2] }} </span>
              <span style="color: #23ddfd">{{ selfIntroductionText[3] }} </span>
              <span style="color: #ffbe4d">{{ selfIntroductionText[4] }}</span>
              {{ selfIntroductionText[5] }}
            </p>
            <br>
            <br>
            <br>
            <br>
            <p>
              {{ mapIntroduction[0] }}
            </p>
            <br>
            <br>
            <p>
              {{ mapIntroduction[1] }}
            </p>
          </div>
        </div>
        <div :style="{height:mapOfChinaHeight,width:'100%'}" ref="myEchart"></div>
        <div class="common-subject">
          <div class="common-text">
            <br>
            <br>
            <p>{{ photographyInfo[0] }}</p>
            <p>{{ photographyInfo[1] }}</p>
            <br>
            <br>
          </div>
        </div>
        <div class="container" :style="{height: containerStyle.height, width: containerStyle.width}">
          <img :src="travelImgs[0]" class="pic1"
               :style="{top: travelImgsStyle[0].top, left: travelImgsStyle[0].left, width: travelImgsStyle[0].width, height:travelImgsStyle[0].height, padding:travelImgsStyle[0].padding}"/>
          <img :src="travelImgs[1]" class="pic2"
               :style="{top: travelImgsStyle[1].top, left: travelImgsStyle[1].left, width: travelImgsStyle[1].width, height:travelImgsStyle[1].height, padding:travelImgsStyle[1].padding}"/>
          <img :src="travelImgs[2]" class="pic3"
               :style="{bottom: travelImgsStyle[2].bottom, right: travelImgsStyle[2].right, width: travelImgsStyle[2].width, height:travelImgsStyle[2].height, padding:travelImgsStyle[2].padding}"/>
          <img :src="travelImgs[3]" class="pic4"
               :style="{bottom: travelImgsStyle[3].bottom, left: travelImgsStyle[3].left, width: travelImgsStyle[3].width, height:travelImgsStyle[3].height, padding:travelImgsStyle[3].padding}"/>
          <img :src="travelImgs[4]" class="pic5"
               :style="{bottom: travelImgsStyle[4].bottom, left: travelImgsStyle[4].left, width: travelImgsStyle[4].width, height:travelImgsStyle[4].height, padding:travelImgsStyle[4].padding}"/>
          <img :src="travelImgs[5]" class="pic6"
               :style="{top: travelImgsStyle[5].top, left: travelImgsStyle[5].left, width: travelImgsStyle[5].width, height:travelImgsStyle[5].height, padding:travelImgsStyle[5].padding}"/>
          <img :src="travelImgs[6]" class="pic7"
               :style="{top: travelImgsStyle[6].top, left: travelImgsStyle[6].left, width: travelImgsStyle[6].width, height:travelImgsStyle[6].height, padding:travelImgsStyle[6].padding}"/>
          <img :src="travelImgs[7]" class="pic8"
               :style="{bottom: travelImgsStyle[7].bottom, right: travelImgsStyle[7].right, width: travelImgsStyle[7].width, height:travelImgsStyle[7].height, padding:travelImgsStyle[7].padding}"/>
          <img :src="travelImgs[8]" class="pic9"
               :style="{top: travelImgsStyle[8].top, left: travelImgsStyle[8].left, width: travelImgsStyle[8].width, height:travelImgsStyle[8].height, padding:travelImgsStyle[8].padding}"/>
          <img :src="travelImgs[9]" class="pic10"
               :style="{top: travelImgsStyle[9].top, left: travelImgsStyle[9].left, width: travelImgsStyle[9].width, height:travelImgsStyle[9].height, padding:travelImgsStyle[9].padding}"/>
        </div>
        <div class="common-subject">
          <div class="common-text">
            <br>
            <br>
            <br>
            <br>
            <br>
            <p>{{ scienceAndTechnology[0] }}</p>
            <p>{{ scienceAndTechnology[1] }}</p>
            <p>{{ scienceAndTechnology[2] }}</p>
            <div class="technology_imgs" :style="technology_style">
              <template>
                <el-carousel :interval="4000" type="card" :height="technology_height" indicator-position="none"
                             arrow="never">
                  <el-carousel-item v-for="(item,index3) in technologyImgs.length" :key="index3">
                    <div class="demo-image__placeholder">
                      <div class="demo-image__error">
                        <div class="block">
                          <el-image :style="'width: 100%; height:'+ technology_height"
                                    :src="technologyImgs[item-1]"></el-image>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </template>
            </div>
            <p>{{ endText[0] }}</p>
            <p>{{ endText[1] }}</p>
          </div>
        </div>
      </div>
      <div class="common"
           v-bind:style="{backgroundImage:'url(' + repXian + ')', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%', height: backgroundHeight}">
      </div>
    </div>
    <div class="end-info common-subject learn-more">
      <div class="social-networks common-text">
        <div class="networks-title-info ">
          <div>想要了解更多？</div>
          <div>你需要的都在这里。</div>
        </div>
        <div v-if="isIphone" class="learn-more-info-iphone">
          <div class="learn-more-info-title">
            <div>社交网络：</div>
          </div>
          <div class="learn-more-info-main">
            <div>
              QQ：66563611
            </div>
            <div>
              抖音：z66563611
            </div>
            <div>
              微博：z66563611
            </div>
            <div>
              邮箱：tqcd@foxmail.com
            </div>
          </div>
        </div>
        <div v-else class="learn-more-info-mac">
          <el-row>
            <el-col :span="4">&nbsp;</el-col>
            <el-col :span="4">
              <div class="learn-more-info-mac-title">
                <div>社交</div>
                <div>网络</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="learn-more-info-mac-main">
                <div>QQ：66563611</div>
                <div>抖音：z66563611</div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="learn-more-info-mac-main">
                <div>微博：z66563611</div>
                <div>邮箱：tqcd@foxmail.com</div>
              </div>
            </el-col>
            <el-col :span="4">&nbsp;</el-col>
          </el-row>
        </div>
      </div>
      <div class="stay—message">
        <div v-if="isIphone" class="stay—message-iphone">
          <div class="stay—message-iphone-title">
            留言板：
          </div>
          <div class="stay—message-iphone-info">
            <div class="stay—message-iphone-info-detailed" v-for="(item,index4) in leavingMessages.length" :key="index4">
              <div class="stay—message-iphone-head-info">
                <el-avatar v-if="isNotEmpty(leavingMessages[item-1].headImg)" :size="29" :src="leavingMessages[item-1].headImg"></el-avatar>
                <el-avatar v-else :size="29" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
                <span class="real-name">{{null === leavingMessages[item - 1].realName || '' === leavingMessages[item - 1].realName || undefined === leavingMessages[item - 1].realName ? '匿名' : leavingMessages[item - 1].realName}}</span>
              </div>
              <div class="stay—message-iphone-info-text">
                {{ leavingMessages[item-1].messageTextInfo }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="stay—message-mac">
          <div class="stay—message-mac-title">
            留言板：
          </div>
          <div class="stay—message-mac-info">
            <el-row>
              <el-col :span="8" v-for="(item,index4) in leavingMessages.length" :key="index4">
                <div class="grid-content bg-purple">
                  <div class="stay—message-mac-info-detailed">
                    <div class="stay—message-mac-head-info">
                      <el-avatar v-if="isNotEmpty(leavingMessages[item-1].headImg)" :size="29" :src="leavingMessages[item-1].headImg"></el-avatar>
                      <el-avatar v-else :size="29" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
                      <span class="real-name">{{null === leavingMessages[item - 1].realName || '' === leavingMessages[item - 1].realName || undefined === leavingMessages[item - 1].realName ? '匿名' : leavingMessages[item - 1].realName}}</span>
                    </div>
                    <div class="stay—message-mac-info-text">
                      {{ leavingMessages[item-1].messageTextInfo }}
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="isIphone" class="stay—message-text-form-iphone">
          <div style="font-weight:500">
            写留言：
          </div>
          <div v-if="fromStayState === 1" class="start-info">
            <el-form :model="fromStay" label-width="80px">
              <div class="input-textarea">
                <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="有什么想对我说的？畅所欲言吧！"
                    v-model="fromStay.stayMessageTextInfo">
                </el-input>
              </div>
              <p>
                留言会公开显示，请勿在留言内容写下微信号等私人联系方式，谨防诈骗。如果你不想发布公开留言，也可以发送邮件到
                <a href="mailto:tqcd@foxmail.com">tqcd@foxmail.com</a>
                与我联系。
              </p>
              <div class="stay—message-text-form-button">
                <el-button type="primary" @click="onSubmit(0)">完成</el-button>
                <el-button @click="doEmptyInfo(0)">清空</el-button>
              </div>
            </el-form>
          </div>
          <div v-else-if="fromStayState === 2" class="be-left-over-info">
            <el-form ref="form" :model="fromStay" label-width="80px">
              <el-upload
                  style="text-align: center;"
                  class="avatar-uploader"
                  action="http://api.tqcd.vip/aMyWebsiteLeavingMessage/saveHeadImg"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                <img v-if="fromStay.stayHeadImg" :src="fromStay.stayHeadImg" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="introduce-info introduce-info-head">(选填)上传头像</div>
              <el-form-item label-width="50px" label="昵称">
                <el-input v-model="fromStay.stayRealName" placeholder="请输入昵称"></el-input>
              </el-form-item>
              <div class="introduce-info introduce-info-input">留空视为匿名。</div>
              <el-form-item label-width="50px" label="网站">
                <el-input v-model="fromStay.stayWebsite" placeholder="http://tqcd.vip"></el-input>
              </el-form-item>
              <div class="introduce-info introduce-info-input">(选填)个人网站或社交网站主页均可。</div>
              <el-form-item label-width="50px" label="邮件">
                <el-input v-model="fromStay.stayMail" placeholder="tqcd@foxmail.com"></el-input>
              </el-form-item>
              <div class="introduce-info introduce-info-input">(选填)当这条留言收到回复时会发送邮件通知你，不会公开显示。</div>
              <div class="stay—message-text-form-button">
                <el-button type="primary" @click="onSubmit(1)">完成</el-button>
                <el-button @click="doEmptyInfo(1)">清空</el-button>
              </div>
            </el-form>
          </div>
          <div v-else class="be-left-over-info stay—message-success">
            <div>你的留言已经保存。</div>
            <div>为防止广告信息，所有留言都会在审核之后才会公开显示。</div>
          </div>
        </div>
        <div v-else class="stay—message-text-form-mac">
          <el-row>
            <el-col :span="8">
              <div class="grid-content bg-purple">&nbsp;</div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple-light">
                <div style="font-weight:500; font-size: 20px">
                  写留言：
                </div>
                <div v-if="fromStayState === 1" class="start-info">
                  <el-form :model="fromStay" label-width="80px">
                    <div class="input-textarea">
                      <el-input
                          type="textarea"
                          :rows="5"
                          placeholder="有什么想对我说的？畅所欲言吧！"
                          v-model="fromStay.stayMessageTextInfo">
                      </el-input>
                    </div>
                    <p>
                      留言会公开显示，请勿在留言内容写下微信号等私人联系方式，谨防诈骗。如果你不想发布公开留言，也可以发送邮件到
                      <a href="mailto:tqcd@foxmail.com">tqcd@foxmail.com</a>
                      与我联系。
                    </p>
                    <div class="stay—message-text-form-button">
                      <el-button type="primary" @click="onSubmit(0)">完成</el-button>
                      <el-button @click="doEmptyInfo(0)">清空</el-button>
                    </div>
                  </el-form>
                </div>
                <div v-else-if="fromStayState === 2" class="be-left-over-info">
                  <el-form ref="form" :model="fromStay" label-width="80px">
                    <el-upload
                        style="text-align: center;"
                        class="avatar-uploader"
                        action="http://api.tqcd.vip/aMyWebsiteLeavingMessage/saveHeadImg"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                      <img v-if="fromStay.stayHeadImg" :src="fromStay.stayHeadImg" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <div class="introduce-info introduce-info-head">(选填)上传头像</div>
                    <el-form-item label-width="50px" label="昵称">
                      <el-input v-model="fromStay.stayRealName" placeholder="请输入昵称"></el-input>
                    </el-form-item>
                    <div class="introduce-info introduce-info-input">留空视为匿名。</div>
                    <el-form-item label-width="50px" label="网站">
                      <el-input v-model="fromStay.stayWebsite" placeholder="http://tqcd.vip"></el-input>
                    </el-form-item>
                    <div class="introduce-info introduce-info-input">(选填)个人网站或社交网站主页均可。</div>
                    <el-form-item label-width="50px" label="邮件">
                      <el-input v-model="fromStay.stayMail" placeholder="tqcd@foxmail.com"></el-input>
                    </el-form-item>
                    <div class="introduce-info introduce-info-input">(选填)当这条留言收到回复时会发送邮件通知你，不会公开显示。</div>
                    <div class="stay—message-text-form-button">
                      <el-button type="primary" @click="onSubmit(1)">完成</el-button>
                      <el-button @click="doEmptyInfo(1)">清空</el-button>
                    </div>
                  </el-form>
                </div>
                <div v-else class="be-left-over-info stay—message-success">
                  <div>你的留言已经保存。</div>
                  <div>为防止广告信息，所有留言都会在审核之后才会公开显示。</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="foot">
      <div>
        <div v-if="footBoolean">
          <a href="https://beian.miit.gov.cn">
            {{ tailInfo[1] }}
          </a>
          <br>
          {{ tailInfo[0] }}
          <br>
          {{ tailInfo[2] }}
        </div>
        <div v-else>
          <span>{{ tailInfo[0] }}</span>
          <span><a href="https://beian.miit.gov.cn" target="_blank">{{ tailInfo[1] }}</a></span>
          <span>{{ tailInfo[2] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xiaHei from '@/assets/images/xia_hei.png'
import xian from '@/assets/images/xian.png'
import repXian from '@/assets/images/rep-xian.png'
import echarts from "echarts";
import '../../node_modules/echarts/map/js/china.js'

export default {
  name: "Index",
  props: ["userJson"],
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      urls: [],
      technologyImgs: [],
      xiaHei: xiaHei,
      xian: xian,
      repXian: repXian,
      isIphone: document.body.clientWidth < 700,
      backgroundHeight: document.body.clientWidth < 700 ? '1400px' : '1800px',
      spanValue: document.body.clientWidth < 700 ? 24 : 12,
      mapOfChinaHeight: document.body.clientWidth < 700 ? '375px' : '900px',
      travelImgs: [],
      travelImgsStyle: [
        {top: '0', left: '400px', width: '239px', height: '200px', padding: '5px 5px 6px'},
        {top: '0', left: '580px', width: '239px', height: '200px', padding: '5px 5px 6px'},
        {bottom: '0', right: '-48px', width: '239px', height: '200px', padding: '5px 5px 6px'},
        {bottom: '0', left: '300px', width: '239px', height: '200px', padding: '5px 5px 6px'},
        {bottom: '0', left: '-33px', width: '239px', height: '200px', padding: '5px 5px 6px'},
        {top: '0', left: '-42px', width: '239px', height: '200px', padding: '5px 5px 6px'},
        {top: '0', left: '780px', width: '239px', height: '200px', padding: '5px 5px 6px'},
        {bottom: '-20px', right: '630px', width: '239px', height: '200px', padding: '5px 5px 6px'},
        {top: '238px', left: '550px', width: '239px', height: '200px', padding: '5px 5px 6px'},
        {top: '20px', left: '180px', width: '239px', height: '200px', padding: '5px 5px 6px'}
      ],
      containerStyle:
          document.body.clientWidth < 700 ? {
            height: '296px', width: "100%"
          } : {
            height: '450px', width: "960px"
          },
      chart: null,
      technology_height: document.body.clientWidth < 700 ? '120px' : document.body.clientWidth < 1000 ? '220px' : '510px',
      technology_style: document.body.clientWidth < 700 ? 'padding: 150px 0 150px 0;' : 'padding: 180px 120px 180px 120px;',
      footBoolean: document.body.clientWidth < 700,
      logo: null,
      headImg: null,
      startText: [],
      selfIntroductionText: [],
      mapIntroduction: [],
      mapMarkerInfo: [],
      photographyInfo: [],
      scienceAndTechnology: [],
      endText: [],
      tailInfo: [],
      bookmark: [],
      fromStay: {
        stayMessageTextInfo: null,
        stayRealName: null,
        stayMail: null,
        stayWebsite: null,
        stayHeadImg: null,
      },
      fromStayState: 1,
      leavingMessages: [],
    };
  },
  methods: {
    isNotEmpty(value) {
      return undefined != value && null != value && value.length != 0;
    },
    getScroll() {
      // 滚动距离的兼容性写法
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (this.scrollTop >= 864) {
        document.querySelector('.header-bar').style.display = 'none'
      } else {
        document.querySelector('.header-bar').style.display = 'block'
      }
    },
    updateTypesetting() {
      if (document.body.clientWidth < 700) {
        this.spanValue = 24;
        this.mapOfChinaHeight = '375px';
        this.travelImgsStyle = [
          {top: '0', left: '46%', width: '119px', height: '100px', padding: '2px 2px 3px'},
          {top: '0', left: '66%', width: '119px', height: '100px', padding: '2px 2px 3px'},
          {bottom: '0', right: '2%', width: '119px', height: '100px', padding: '2px 2px 3px'},
          {bottom: '0', left: '48%', width: '119px', height: '100px', padding: '2px 2px 3px'},
          {bottom: '0', left: '25%', width: '119px', height: '100px', padding: '2px 2px 3px'},
          {top: '0', left: '0px', width: '119px', height: '100px', padding: '2px 2px 3px'},
          {top: '100px', left: '18%', width: '119px', height: '100px', padding: '2px 2px 3px'},
          {bottom: '-10px', right: '68%', width: '119px', height: '100px', padding: '2px 2px 3px'},
          {top: '100px', left: '55%', width: '119px', height: '100px', padding: '2px 2px 3px'},
          {top: '10px', left: '19%', width: '119px', height: '100px', padding: '2px 2px 3px'},
        ];
        this.backgroundHeight = "1400px";
        this.technology_height = '120px';
        this.technology_style = 'padding: 150px 0 150px 0;';
      } else if (document.body.clientWidth < 1000) {
        this.technology_height = '220px';
      } else {
        this.spanValue = 12;
        this.mapOfChinaHeight = '900px';
        this.travelImgsStyle = [
          {top: '0', left: '400px', width: '239px', height: '200px', padding: '5px 5px 6px'},
          {top: '0', left: '580px', width: '239px', height: '200px', padding: '5px 5px 6px'},
          {bottom: '0', right: '-48px', width: '239px', height: '200px', padding: '5px 5px 6px'},
          {bottom: '0', left: '300px', width: '239px', height: '200px', padding: '5px 5px 6px'},
          {bottom: '0', left: '-33px', width: '239px', height: '200px', padding: '5px 5px 6px'},
          {top: '0', left: '-42px', width: '239px', height: '200px', padding: '5px 5px 6px'},
          {top: '0', left: '780px', width: '239px', height: '200px', padding: '5px 5px 6px'},
          {bottom: '-20px', right: '630px', width: '239px', height: '200px', padding: '5px 5px 6px'},
          {top: '238px', left: '550px', width: '239px', height: '200px', padding: '5px 5px 6px'},
          {top: '20px', left: '180px', width: '239px', height: '200px', padding: '5px 5px 6px'}
        ];
        this.backgroundHeight = "1800px";
        this.technology_height = '510px';
        this.technology_style = 'padding: 180px 120px 180px 120px;';
      }
    },
    chinaConfigure() {
      // 全国省份列表
      var dataMap = [{name: '北京'}, {name: '天津'}, {name: '上海'}, {name: '重庆'}, {name: '河北'}, {name: '河南'}, {name: '云南'}, {name: '辽宁'}, {name: '黑龙江'}, {name: '湖南'}, {name: '安徽'}, {name: '山东'},
        {name: '新疆'}, {name: '江苏'}, {name: '浙江'}, {name: '江西'}, {name: '湖北'}, {name: '广西'}, {name: '甘肃'}, {name: '山西'}, {name: '内蒙古'}, {name: '陕西'}, {name: '吉林'}, {name: '福建'}, {name: '贵州'},
        {name: '广东'}, {name: '青海'}, {name: '西藏'}, {name: '四川'}, {name: '宁夏'}, {name: '海南'}, {name: '台湾'}, {name: '香港'}, {name: '澳门'}]
      // 需要在页面上直接标记出来的城市
      var specialMap = this.mapMarkerInfo;
      // 对dataMap进行处理，使其可以直接在页面上展示
      for (var i = 0; i < specialMap.length; i++) {
        for (var j = 0; j < dataMap.length; j++) {
          if (specialMap[i] === dataMap[j].name) {
            dataMap[j].selected = true;
            break;
          }
        }
      }
      let myChart = echarts.init(this.$refs.myEchart); //这里是为了获得容器所在位置
      window.onresize = myChart.resize;
      myChart.setOption({
        tooltip: {
          formatter: function (params) {
            return '<span style="font-size:14px">' + params.name;
          },
          backgroundColor: "#ff7f50",//提示标签背景颜色
          textStyle: {color: "#fff"} //提示标签字体颜色
        },
        series: [
          {
            name: '中国',
            type: 'map',
            mapType: 'china',
            selectedMode: 'multiple',
            label: {
              normal: {
                show: document.body.clientWidth >= 700,//显示省份标签
                // textStyle:{color:"#c71585"}//省份标签字体颜色
              },
              emphasis: {
                show: document.body.clientWidth >= 700,//对应的鼠标悬浮效果
                // textStyle:{color:"#800080"}
              }
            },
            itemStyle: {
              normal: {
                borderWidth: .5,//区域边框宽度
                // borderColor: '#009fe8',//区域边框颜色
                // areaColor:"#ffefd5",//区域颜色
              },
              emphasis: {
                borderWidth: .5,
                borderColor: '#4b0082',
                areaColor: "#ffdead",
              }
            },
            data: dataMap
          }
        ]
      })
    },
    sortMethod(arr) {
      for (var i = 0; i < arr.length - 1; i++) {
        for (var j = 0; j < arr.length - 1 - i; j++) {
          if (parseInt(arr[j].sort) > parseInt(arr[j + 1].sort)) {
            var temp = arr[j];
            arr[j] = arr[j + 1];
            arr[j + 1] = temp;
          }
        }
      }
      var result = [];
      for (var n = 0; n < arr.length; n++) {
        result[n] = arr[n].img;
      }
      return result;
    },
    isNull(value) {
      if (value === null || value === undefined || value === '') {
        return true;
      }
    },
    doEmptyInfo(type) {
      if (0 === type) {
        this.fromStay.stayMessageTextInfo = "";
      } else {
        this.fromStay.stayRealName = "";
        this.fromStay.stayMail = "";
        this.fromStay.stayWebsite = "";
      }
    },
    onSubmit(type) {
      if (0 === type) {
        if (this.fromStay.stayMessageTextInfo === "" || null === this.fromStay.stayMessageTextInfo || undefined === this.fromStay.stayMessageTextInfo) {
          this.$notify({
            title: '提示',
            message: '留言内容不可为空！',
            type: 'warning'
          });
        } else if (this.fromStay.stayMessageTextInfo.length <= 5) {
          this.$notify({
            title: '提示',
            message: '留言内容必须大于五位！',
            type: 'warning'
          });
        } else if (this.fromStay.stayMessageTextInfo.length > 500) {
          this.$notify({
            title: '提示',
            message: '留言内容必须小于五百位！',
            type: 'warning'
          });
        } else {
          this.fromStayState = 2;
        }
      } else {
        //保存留言内容
        this.$axios.post("/aMyWebsiteLeavingMessage/saveAMyWebsiteLeavingMessage", {
          "hideState": "0",
          "messageTextInfo": this.fromStay.stayMessageTextInfo,
          "realName": this.fromStay.stayRealName,
          "website": this.fromStay.stayWebsite,
          "mail": this.fromStay.stayMail,
          "headImg": this.fromStay.stayHeadImg
        }).then(response => {
          var result = response.data;
          if (200 === result.code) {
            this.$notify({
              title: '成功',
              message: '保存成功！',
              type: 'success'
            });
            this.fromStayState = 3;
          } else {
            this.$notify({
              title: '提示',
              message: '保存失败：' + result.msg,
              type: 'warning'
            });
          }
        }).catch(function (err) {
          this.$notify({
            title: '错误',
            message: "请求失败" + err,
            type: 'error'
          });
        })
      }
    },
    handleAvatarSuccess(res) {
      if (200 === res.code) {
        this.fromStay.stayHeadImg = res.data;
      } else {
        this.$message.error('上传头像图片未知错误！');
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    dataURItoBlob(dataURI, type) {
      var binary = atob(dataURI.split(',')[1]);
      var array = [];
      for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {type: type});
    },
  },
  mounted: function () {
    //设置兼容初始化
    window.addEventListener('scroll', this.getScroll)
    this.updateTypesetting()
    window.onresize = () => {
      return (() => {
        this.updateTypesetting()
      })();
    };

    // this.$message({
    //   message: '通知：留言功能已开放！',
    //   type: 'success',
    //   duration: 2000
    // });



    //执行请求后端初始化参数
    this.$axios.get("/aMyWebsiteBaseConfig/getAMyWebsiteBaseConfig").then(response => {
      var result = response.data;
      if (200 === result.code) {
        var data = result.data;
        this.logo = data.logo;
        this.headImg = data.headImg;
        this.startText = eval("(" + data.startText + ")");
        this.selfIntroductionText = eval("(" + data.selfIntroductionText + ")");
        this.mapIntroduction = eval("(" + data.mapIntroduction + ")");
        this.mapMarkerInfo = eval("(" + data.mapMarkerInfo + ")");
        this.photographyInfo = eval("(" + data.photographyInfo + ")");
        this.scienceAndTechnology = eval("(" + data.scienceAndTechnology + ")");
        this.endText = eval("(" + data.endText + ")");
        this.tailInfo = eval("(" + data.tailInfo + ")");
        //设置兼容初始化
        this.chinaConfigure();
      } else {
        this.$message.error("错误" + result);
      }
    }).catch(function (err) {
      this.$message.error("请求失败" + err);
    })

    //初始化图片
    this.$axios.get("/aMyWebsiteHomeImgs/getAllAMyWebsiteHomeImgs").then(response => {
      var result = response.data;
      if (200 === result.code) {
        var data = result.data;
        var banners = [];
        var photography = [];
        var scienceAndTechnology = [];
        for (var i = 0; i < data.length; i++) {
          var dataOne = data[i];
          if ("Y" === dataOne.status) {
            switch (dataOne.type) {
              case "banner":
                banners[banners.length] = dataOne;
                break;
              case "photography":
                photography[photography.length] = dataOne;
                break;
              case "science_and_technology":
                scienceAndTechnology[scienceAndTechnology.length] = dataOne;
            }
          }
        }
        this.urls = this.sortMethod(banners);
        this.travelImgs = this.sortMethod(photography);
        this.technologyImgs = this.sortMethod(scienceAndTechnology);
      } else {
        this.$message.error("错误" + result);
      }
    }).catch(function (err) {
      this.$message.error("请求失败" + err);
    })

    //初始化标签栏
    this.$axios.post("/aMyWebsiteBaseHomeBookmark/findAllByAMyWebsiteBaseHomeBookmark/0/2147483647", {"hideState": "0"}).then(response => {
      var result = response.data;
      if (200 === result.code) {
        this.bookmark = result.data.records;
        console.log(result.data.records);
      } else {
        this.$message.error("错误" + result);
      }
    }).catch(function (err) {
      this.$message.error("请求失败" + err);
    })

    //获取留言内容
    this.$axios.post("/aMyWebsiteLeavingMessage/findAllByAMyWebsiteLeavingMessage/0/6",{"hideState": "0"}).then(response => {
      var result = response.data;
      if (200 === result.code) {
        this.leavingMessages = result.data.records;
      } else {
        this.$message.error("错误" + result);
      }
    }).catch(function (err) {
      this.$message.error("请求失败" + err);
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  created() {
    setTimeout(() => {
      window.L2Dwidget.init({
        //小白猫
        // pluginModelPath: 'live2d-widget-model-tororo/assets/',
        // model: { jsonPath: 'static/live2dw/live2d-widget-model-tororo/assets/tororo.model.json' },
        //初音未来
        pluginModelPath: 'live2d-widget-model-miku/assets/',
        model: { jsonPath: 'static/live2dw/live2d-widget-model-miku/assets/miku.model.json' },
        pluginRootPath: 'static/live2dw/',
        pluginJsPath: 'lib/',
        tagMode: false,
        debug: false,
        display: { position: 'right', width: 150, height: 300 },
        mobile: { show: true },
        log: false
      })
    }, 1000)
  },
};
</script>

<style lang="scss">
.home {
  background-color: #021320;
  padding: 0;

  .personal-info {
    pointer-events: none;
    display: inline-grid;
    position: relative;
    z-index: 99;
    text-align: center;
    align-items: center;
    top: 248px;

    .personal-info-text {
      font-size: 38px;
      color: white;
      font-weight: bold;

      div {
        padding-bottom: 20px;
      }
    }
  }

  .header-bar {
    position: absolute;
    top: 0;
    z-index: 99;


    .logo-image {
      position: fixed;
      left: 20px;
      top: 20px;
    }

    .menu {
      position: fixed;
      top: 18px;
      right: 28px;
    }
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #ffffff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .logo-text-menu {
    font-size: 38px;
  }

  .el-carousel__container {
    z-index: 99;
  }

  .background-imgs {
    text-align: center;
    position: absolute;
    width: 100%;
  }

  .xia-hei-img {
    position: relative;
    z-index: 99;
    top: 569px;

    div {
      height: 380px;
    }
  }

  .header {
    height: 945px
  }

  .common-subject {
    margin: 0 28px 0 28px;

    .common-text {
      color: white;
      font-size: 23px;
      text-align: center;
    }
  }

  /*css3实现照片墙的样式*/
  .container {
    margin: 60px auto;
    position: relative;
  }

  .container img {
    position: absolute;
    padding: 10px 10px 15px;
    background: #fff;
    border: 1px solid #ddd;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
    z-index: 1;
  }

  .container img:hover {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-box-shadow: 5px 5px 5px #ddd;
    -moz-box-shadow: 5px 5px 5px #ddd;
    box-shadow: 5px 5px 5px #ddd;
    z-index: 2;
    cursor: pointer;
  }

  .pic1 {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  .pic2 {
    -webkit-transform: rotate(356deg);
    -moz-transform: rotate(356deg);
    transform: rotate(356deg);
  }

  .pic3 {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  .pic4 {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  .pic5 {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  .pic6 {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }

  .pic7 {
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  .pic8 {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  .pic9 {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  .pic10 {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  .foot {
    background-color: #2b2f3a;
    color: #c0d2d2;
    text-align: center;
    padding: 3px;

    span {
      padding: 0 10px 0 10px;
    }
  }

  .end-info {
    //background-color: #273048;
    color: white;
  }

  .learn-more {
    .learn-more-info-iphone {
      padding: 129px 28px 0 12px;
      text-align: left;

      .learn-more-info-title {
        font-size: 23px;
        padding-bottom: 6px;
        font-weight: 500;
      }

      .learn-more-info-main {
        div {
          padding-top: 6px;
          margin-left: 18px;
          padding-bottom: 6px;
          border-bottom: 1px solid #7a7a7a;
        }

        font-size: 19px;
      }
    }

    .learn-more-info-mac {
      padding: 88px 40px 0px 40px;
      text-align: left;

      .learn-more-info-mac-title {
        div {
          padding: 6px;
        }
      }

      .learn-more-info-mac-main {
        div {
          padding: 6px;
          border-bottom: 1px solid #7a7a7a;
        }
      }
    }
  }

  .stay—message {
    .stay—message-iphone {
      padding: 129px 28px 0 12px;
      text-align: left;

      .stay—message-iphone-title {
        font-size: 23px;
        padding-bottom: 6px;
        font-weight: 500;
      }

      .stay—message-iphone-info {
        .stay—message-iphone-info-detailed {
          padding: 12px 0 0 12px;

          .stay—message-iphone-head-info {
            position: relative;

            .real-name {
              height: 100%;
              display: inline-block;
              vertical-align: middle;
              font-size: 20px;
              position: absolute;
              top: 5%;
              left: 36px;
            }
          }

          .stay—message-iphone-info-text {
            padding-left: 23px;
          }
        }
      }
    }

    .stay—message-mac {
      padding: 110px;

      .stay—message-mac-title {
        padding-left: 60px;
        font-size: 23px;
        padding-bottom: 6px;
        font-weight: 500;
      }

      .stay—message-mac-info {
        padding: 0 80px 0 80px;
        .stay—message-mac-info-detailed {
          padding: 12px 0 0 12px;

          .stay—message-mac-head-info {
            position: relative;

            .real-name {
              height: 100%;
              display: inline-block;
              vertical-align: middle;
              font-size: 20px;
              position: absolute;
              top: 5%;
              left: 36px;
            }
          }

          .stay—message-mac-info-text {
            padding-left: 23px;
          }
        }
      }

      .stay—message-mac-info-detailed {
        padding: 16px;
      }
    }

    .stay—message-text-form-iphone {
      padding: 99px 6px 99px 6px;

      /* 留言内容 */
      .input-textarea {
        padding-top: 12px;
      }

      .el-textarea__inner {
        color: #ffffff;
        background-color: #505050;
        border: 1px solid #525252;
      }

      .stay—message-text-form-button {
        text-align: center;
        margin-top: 16px;
      }


      /* 上传头像 */
      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 125px;
        height: 125px;
        line-height: 125px;
        text-align: center;
      }

      .avatar {
        width: 178px;
        height: 178px;
        display: block;
      }

      .avatar-uploader {
        margin-top: 18px;
      }

      /* 补全信息 */
      .be-left-over-info {
        .el-form-item__label {
          width: 50px;
          color: #efefef;
        }

        .el-form-item__content {
          margin-left: 50px;
        }

        .el-input__inner {
          color: #ffffff;
          background-color: #505050;
          border: 1px solid #525252;
        }

        .introduce-info {
          font-size: 12px;
          margin-top: 5px;
        }

        .introduce-info-head {
          text-align: center;
        }

        .introduce-info-input {
          padding-left: 50px;
        }

        .el-form-item {
          margin: 18px 0 0 0;
        }
      }
    }

    .stay—message-text-form-mac {
      padding: 59px 10px 230px 10px;

      /* 留言内容 */
      .input-textarea {
        padding-top: 12px;
      }

      .el-textarea__inner {
        color: #ffffff;
        background-color: #505050;
        border: 1px solid #525252;
      }

      .stay—message-text-form-button {
        text-align: center;
        margin-top: 16px;
      }


      /* 上传头像 */
      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 125px;
        height: 125px;
        line-height: 125px;
        text-align: center;
      }

      .avatar {
        width: 178px;
        height: 178px;
        display: block;
      }

      .avatar-uploader {
        margin-top: 18px;
      }

      /* 补全信息 */
      .be-left-over-info {
        .el-form-item__label {
          width: 50px;
          color: #efefef;
        }

        .el-form-item__content {
          margin-left: 50px;
        }

        .el-input__inner {
          color: #ffffff;
          background-color: #505050;
          border: 1px solid #525252;
        }

        .introduce-info {
          font-size: 12px;
          margin-top: 5px;
        }

        .introduce-info-head {
          text-align: center;
        }

        .introduce-info-input {
          padding-left: 50px;
        }

        .el-form-item {
          margin: 18px 0 0 0;
        }
      }
    }

    .stay—message-success {
      font-size: 16px;
      text-align: center;
      color: #dddddd;

      div {
        padding-top: 38px;
      }
    }
  }
}
</style>

